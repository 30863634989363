import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Wrapper from "../../components/user/wrapper";
import Title from "../../components/user/title";
import Ticket from "../../components/user/ticket";
import Circuits from "../../components/user/circuits";
import SubMenu from "../../components/user/submenu";

const Holder = styled.div`
    padding: 48px;
    height: 100%;
    position: relative;
    flex: 1;

    @media (max-width: 1024px) {
        padding: 16px;
    }
`;

const Hide = styled.div`
    display: none;

    @media (max-width: 1024px) {
        display: block;
    }
`;

const Bottom = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 50%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export default function Tickets() {
    const [user, setUser] = useState();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper
            backgroundLeft={"#000"}
            backgroundRight={"#111"}
            footerType={0}
            login={true}
            loginCallback={setUser}
        >
            <Holder>
                <Title color={"#fff"}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                    >
                        <path
                            d="M27.9253 13.7136C27.445 13.7136 27.0574 13.304 27.0574 12.8L27.0574 10.5136C27.0574 9.7568 26.4737 9.1424 25.7547 9.1424L23.5826 9.1424C23.1023 9.1424 22.7147 8.7328 22.7147 8.2288L22.7147 5.9424C22.7147 5.1856 22.131 4.5712 21.4121 4.5712L19.24 4.5712C18.7597 4.5712 18.3721 4.1616 18.3721 3.6576L18.3721 1.3712C18.3721 0.6144 17.7884 -6.78171e-10 17.0694 -6.49129e-10L9.6868 -3.50905e-10L9.6868 4.5712L17.5042 4.5712C17.9845 4.5712 18.3721 4.9808 18.3721 5.4848L18.3721 7.7712C18.3721 8.528 18.9558 9.1424 19.6747 9.1424L21.8468 9.1424C22.3271 9.1424 22.7147 9.552 22.7147 10.056L22.7147 12.7984C22.7147 13.304 22.3256 13.712 21.8468 13.712L1 13.712L1 18.2832L21.8453 18.2832C22.3256 18.2832 22.7132 18.6928 22.7132 19.1968L22.7132 21.9392C22.7132 22.4448 22.3241 22.8528 21.8453 22.8528L19.6732 22.8528C18.9542 22.8528 18.3706 23.4672 18.3706 24.224L18.3706 26.5104C18.3706 27.016 17.9814 27.424 17.5026 27.424L9.68528 27.424L9.68528 31.9952L17.0679 31.9952C17.7869 31.9952 18.3706 31.3808 18.3706 30.624L18.3706 28.3376C18.3706 27.832 18.7597 27.424 19.2385 27.424L21.4106 27.424C22.1295 27.424 22.7132 26.8096 22.7132 26.0528L22.7132 23.7664C22.7132 23.2608 23.1023 22.8528 23.5811 22.8528L25.7532 22.8528C26.4722 22.8528 27.0558 22.2384 27.0558 21.4816L27.0558 19.1952C27.0558 18.6896 27.445 18.2816 27.9238 18.2816L31.3985 18.2816L31.3985 13.7104L27.9238 13.7104L27.9253 13.7136ZM23.5826 18.2848C23.1023 18.2848 22.7147 17.8752 22.7147 17.3712L22.7147 14.6288C22.7147 14.1232 23.1038 13.7152 23.5826 13.7152L26.1879 13.7152C26.6682 13.7152 27.0558 14.1248 27.0558 14.6288L27.0558 17.3712C27.0558 17.8768 26.6667 18.2848 26.1879 18.2848L23.5826 18.2848Z"
                            fill="white"
                        />
                    </svg>
                    Minhas Reservas
                </Title>
                <br />
                <Ticket user={user} />
                <Hide>
                    {user && user.circuits.length > 0 && (
                        <Circuits circuits={user.circuits} />
                    )}
                </Hide>
                <Bottom>
                    <SubMenu page={0} />
                </Bottom>
            </Holder>
            {user && user.circuits.length > 0 && (
                <Circuits circuits={user.circuits} />
            )}
        </Wrapper>
    );
}
