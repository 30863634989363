import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Page from "../page";

const Holder = styled.div`
    width: 100%;
`;

const Display = styled.div`
    width: 100%;
    position: relative;

    video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: transparent;
        z-index: 0;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
`;

const Logo = styled.div`
    padding-bottom: 48px;
    margin-left: 48px;

    @media (max-width: 1024px) {
        img {
            width: 248px;
            position: absolute;
            left: 16px;
            top: 120px;
        }
    }
`;

const Box = styled.div`
    width: 540px;
    height: auto;
    padding: 48px;
    background: #fff;
    position: relative;

    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 40px */

    img {
        position: absolute;
        width: 182px;
        left: 100%;
        top: 100%;
    }

    @media (max-width: 1024px) {
        font-size: 24px;

        img {
            width: 72px;
            left: 100%;
            top: -72px;
        }
    }
`;

const Blue = styled.div`
    width: 540px;
    height: 182px;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export default function Intro() {
    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        });
    }, []);

    return (
        <Holder>
            <Display style={{ height: height }}>
                <video autoPlay playsInline muted loop>
                    <source src="/videos/loop.mp4" type="video/mp4" />
                </video>
                <Page>
                    <Content style={{ height: height }}>
                        <Logo>
                            <img src="/img/logo_intro.svg" alt="Arena Pixel" />
                        </Logo>
                        <Box style={{ width: width < 1024 ? width - 72 : 540 }}>
                            A primeira arena de jogos interativos do Brasil
                            <img src="/img/logo_blue.svg" alt="Arena Pixel" />
                        </Box>
                        <Blue></Blue>
                    </Content>
                </Page>
            </Display>
        </Holder>
    );
}
