import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default function Helper() {
    const isMobile = function () {
        return window.innerWidth <= 768;
    };
    const screenWidth = function () {
        return window.innerWidth;
    };
    const screenHeight = function () {
        return window.innerHeight;
    };
    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const formattedDate = format(date, "dd MMM yyyy", { locale: ptBR });
        const formattedDay = format(date, "E", {
            locale: ptBR,
        }).substring(0, 3);
        // const formattedTime = format(date, "HH'h'mm", {
        //     locale: ptBR,
        // });

        const formattedTime = `${isoDateString.substring(
            11,
            13
        )}h${isoDateString.substring(14, 16)}`;
        return { formattedDate, formattedDay, formattedTime };
    };
    const generateRandomTeamName = () => {
        const prefixos = [
            "Super",
            "Mega",
            "Ultra",
            "Hiper",
            "Incríveis",
            "Malucas",
            "Selvagens",
            "Épicas",
            "Divertidas",
            "Loucas",
            "Zanys",
            "Nerds",
            "Bizarras",
            "Estranhas",
            "Engraçadas",
            "Alegres",
            "Felizes",
            "Animadas",
            "Excêntricas",
            "Patetas",
            "Lunáticas",
            "Esquisitas",
            "Divertidas",
            "Borbulhantes",
            "Malukas",
            "Jazzy",
            "Rockeiras",
            "Groovys",
            "Punks",
            "Zumbidas",
            "Piradas",
            "Chamativas",
            "Estilosas",
            "Fashion",
            "Elegantes",
            "Saltitantes",
            "Barulhentas",
            "Vibrantes",
            "Cheias de vida",
            "Brilhantes",
            "Gélidas",
            "Tranquilas",
            "Perfeitas",
            "Tontas",
            "Sorridentes",
            "Saltitantes",
            "Agitadas",
            "Brincalhonas",
            "Animadas",
            "Cheias de energia",
            "Zumbidas",
            "Doidonas",
            "Engraçadinhas",
            "Excêntricas",
            "Glamorosas",
            "Dapper",
            "Chiques",
            "Esnobes",
            "Fashionistas",
            "Flamboyant",
            "Reluzentes",
            "Luminosas",
            "Radiantes",
            "Cintilantes",
            "Fulgurantes",
            "Alegres",
            "Radiosas",
            "Joviais",
            "Exuberantes",
            "Festivas",
            "Animadas",
            "Espumantes",
            "Vivaças",
            "Breezy",
            "Solares",
            "Arco-íris",
            "Estreladas",
            "Lunares",
            "Estelares",
            "Galácticas",
            "Cósmicas",
            "Interstelares",
            "Orbitais",
            "Meteoritas",
            "Astrais",
            "Luz",
            "Brilhantes",
            "Resplandecentes",
            "Gargalhadas",
            "Saltitantes",
            "Pulantes",
            "Astutas",
            "Ardilosas",
            "Amorosas",
            "Fofas",
            "Brincalhonas",
            "Fofinhas",
            "Poderosas",
            "Geniais",
            "Radiantes",
            "Impactantes",
            "Ferozes",
            "Valentes",
            "Corajosas",
            "Bravas",
            "Heroicas",
            "Formidáveis",
            "Intimidadoras",
            "Misteriosas",
            "Enigmáticas",
            "Sábias",
            "Fantásticas",
            "Fabulosas",
            "Sensacionais",
            "Deslumbrantes",
            "Espetaculares",
            "Fascinantes",
            "Encantadoras",
            "Carismáticas",
            "Charmos",
            "Maravilhosas",
            "Magníficas",
            "Gloriosas",
            "Sublimes",
            "Incríveis",
            "Extraordinárias",
            "Estonteantes",
            "Impressionantes",
            "Espantosas",
            "Estupendas",
            "Fantabulosas",
            "Majestosas",
            "Reais",
            "Imperiais",
            "Nobres",
            "Soberanas",
            "Regais",
            "Patriotas",
            "Leais",
            "Fiéis",
            "Devotadas",
            "Dedicadas",
            "Confiáveis",
            "Solidárias",
            "Aliadas",
            "Parceiras",
            "Unidas",
            "Apoiadoras",
            "Guardiãs",
            "Protetoras",
            "Defensoras",
            "Heroínas",
            "Líderes",
            "Mestres",
            "Inventivas",
            "Criativas",
            "Inovadoras",
            "Visionárias",
            "Inspiradoras",
            "Conectadas",
            "Intelectuais",
            "Reflexivas",
            "Filósofas",
            "Pensadoras",
            "Virtuosas",
            "Talentosas",
            "Habilidosas",
            "Competentes",
            "Capazes",
            "Profissionais",
            "Esportistas",
            "Atléticas",
            "Saudáveis",
            "Fortes",
            "Resilientes",
            "Corajosas",
            "Destemidas",
            "Astutas",
            "Velozes",
            "Ágeis",
            "Flexíveis",
            "Robustas",
            "Vigorosas",
            "Energéticas",
            "Dinâmicas",
            "Vitais",
            "Vibrantes",
            "Luminosas",
            "Radiantes",
            "Gloriosas",
        ];

        const sufixos = [
            "Guerreiras",
            "Cavaleiras",
            "Piratas",
            "Ninjas",
            "Samurais",
            "Vikings",
            "Magas",
            "Dragões",
            "Titãs",
            "Gigantes",
            "Bestas",
            "Monstros",
            "Fantasmas",
            "Espectros",
            "Assombrações",
            "Demônias",
            "Anjas",
            "Estranhas",
            "Desajustadas",
            "Patetas",
            "Malucas",
            "Lunáticas",
            "Loucas",
            "Palhaças",
            "Bobas",
            "Mímicas",
            "Jogadoras",
            "Mágicas",
            "Ilusionistas",
            "Truqueiras",
            "Rascals",
            "Renegadas",
            "Rebeldes",
            "Fora-da-lei",
            "Bandidas",
            "Marauders",
            "Pioneiras",
            "Exploradoras",
            "Aventureiras",
            "Viajantes",
            "Peregrinas",
            "Nômades",
            "Buscadoras",
            "Desbravadoras",
            "Guias",
            "Líderes",
            "Capitãs",
            "Comandantes",
            "Chefes",
            "Imperadoras",
            "Rainhas",
            "Princesas",
            "Duquesas",
            "Baronesas",
            "Condessas",
            "Viscondessas",
            "Escudeiras",
            "Senhoras",
            "Patriotas",
            "Patronas",
            "Heroínas",
            "Campeãs",
            "Vencedoras",
            "Triunfadoras",
            "Conquistadoras",
            "Defensoras",
            "Protetoras",
            "Guardiãs",
            "Sentinelas",
            "Escudos",
            "Combatentes",
            "Resistentes",
            "Oponentes",
            "Competidoras",
            "Atletas",
            "Esportistas",
            "Concentradas",
            "Determinadas",
            "Fortes",
            "Energéticas",
            "Vitais",
            "Vigorosas",
            "Potentes",
            "Velozes",
            "Ágeis",
            "Flexíveis",
            "Destros",
            "Habilidosas",
            "Competentes",
            "Talentosas",
            "Capazes",
            "Aptas",
            "Peritas",
            "Profissionais",
            "Experientes",
            "Sábias",
            "Inteligentes",
            "Astutas",
            "Espertas",
            "Sapiência",
            "Discernidoras",
            "Intuitivas",
            "Compreensivas",
            "Entendidas",
            "Perceptivas",
            "Visionárias",
            "Perspicazes",
            "Sagazes",
            "Reflexivas",
            "Juízas",
            "Prudentes",
            "Racionais",
            "Lógicas",
            "Razoáveis",
            "Criativas",
            "Imaginativas",
            "Inovadoras",
            "Pensadoras",
            "Artistas",
            "Músicas",
            "Escritoras",
            "Poetas",
            "Pintoras",
            "Escultoras",
            "Inventoras",
            "Cientistas",
            "Pesquisadoras",
            "Desenvolvedoras",
            "Engenheiras",
            "Programadoras",
            "Construtoras",
            "Arquitetas",
            "Designers",
            "Planejadoras",
            "Estratégas",
            "Consultoras",
            "Mentoras",
            "Coaches",
            "Treinadoras",
            "Instrutoras",
            "Educadoras",
            "Professoras",
            "Tutoras",
            "Orientadoras",
            "Monitoras",
            "Supervisoras",
            "Administradoras",
            "Gestoras",
            "Coordenadoras",
            "Diretoras",
            "Executivas",
            "Presidentes",
            "CEOs",
            "Gerentes",
            "Empresárias",
            "Empreendedoras",
            "Investidoras",
            "Financistas",
            "Bancárias",
            "Contadoras",
            "Patrocinadoras",
            "Promotoras",
            "Parceiras",
            "Aliadas",
            "Camaradas",
            "Companheiras",
            "Cúmplices",
            "Aliadas",
            "Amigas",
            "Parceiras",
            "Unidas",
            "Apoiadoras",
            "Guardadoras",
            "Protetoras",
            "Defensoras",
            "Heroínas",
            "Líderes",
            "Mestres",
        ];
        const prefixoAleatorio =
            prefixos[Math.floor(Math.random() * prefixos.length)];
        const sufixoAleatorio =
            sufixos[Math.floor(Math.random() * sufixos.length)];
        return prefixoAleatorio + " " + sufixoAleatorio;
    };
    const generateRandomSurName = () => {
        const prefixosMasculinos = [
            "Bravo",
            "Forte",
            "Ágil",
            "Corajoso",
            "Destemido",
            "Valente",
            "Heroico",
            "Audaz",
            "Astuto",
            "Feroz",
            "Nobre",
            "Leal",
            "Devoto",
            "Sábio",
            "Astuto",
            "Virtuoso",
            "Genial",
            "Talentoso",
            "Inovador",
            "Inventivo",
            "Criativo",
            "Visionário",
            "Líder",
            "Mestre",
            "Senhor",
            "Protetor",
            "Guardião",
            "Defensor",
            "Lutador",
            "Guerreiro",
            "Comandante",
            "Capitão",
            "Vencedor",
            "Triunfador",
            "Conquistador",
            "Explorador",
            "Aventureiro",
            "Navegador",
            "Pioneiro",
            "Descobridor",
            "Desbravador",
            "Buscador",
            "Investigador",
            "Pesquisador",
            "Cientista",
            "Inventor",
            "Engenheiro",
            "Programador",
            "Desenvolvedor",
            "Construtor",
            "Arquitecto",
            "Designer",
            "Planejador",
            "Estratégista",
            "Consultor",
            "Mentor",
            "Treinador",
            "Instrutor",
            "Educador",
            "Professor",
            "Tutor",
            "Orientador",
            "Monitor",
            "Supervisor",
            "Administrador",
            "Gestor",
            "Coordenador",
            "Diretor",
            "Executivo",
            "Presidente",
            "Empresário",
            "Empreendedor",
            "Investidor",
            "Financista",
            "Bancário",
            "Contador",
            "Patrocinador",
            "Promotor",
            "Parceiro",
            "Aliado",
            "Camarada",
            "Companheiro",
            "Cúmplice",
            "Amigo",
            "Apoiador",
            "Guardiã",
            "Protetor",
            "Defensor",
            "Herói",
            "Líder",
        ];

        const sufixosMasculinos = [
            "Guerreiro",
            "Cavaleiro",
            "Pirata",
            "Ninja",
            "Samurai",
            "Viking",
            "Mago",
            "Dragão",
            "Titã",
            "Gigante",
            "Besta",
            "Monstro",
            "Fantasma",
            "Espectro",
            "Assombração",
            "Demônio",
            "Anjo",
            "Estranho",
            "Desajustado",
            "Pateta",
            "Maluco",
            "Lunático",
            "Louco",
            "Palhaço",
            "Bobo",
            "Mímico",
            "Jogador",
            "Mágico",
            "Ilusionista",
            "Truqueiro",
            "Rascal",
            "Renegado",
            "Rebelde",
            "Fora-da-lei",
            "Bandido",
            "Marauder",
            "Pioneiro",
            "Explorador",
            "Aventureiro",
            "Viajante",
            "Peregrino",
            "Nômade",
            "Buscador",
            "Desbravador",
            "Guia",
            "Líder",
            "Capitão",
            "Comandante",
            "Chefe",
            "Imperador",
            "Rei",
            "Príncipe",
            "Duque",
            "Barão",
            "Conde",
            "Visconde",
            "Escudeiro",
            "Senhor",
            "Patriota",
            "Patrono",
            "Herói",
            "Campeão",
            "Vencedor",
            "Triunfador",
            "Conquistador",
            "Defensor",
            "Protetor",
            "Guardiã",
            "Sentinela",
            "Escudo",
            "Combatente",
            "Resistente",
            "Oponente",
            "Competidor",
            "Atleta",
            "Esportista",
            "Concentrado",
            "Determinado",
            "Forte",
            "Energético",
            "Vital",
            "Vigoroso",
            "Potente",
            "Veloz",
            "Ágil",
            "Flexível",
            "Dextro",
            "Habilidoso",
            "Competente",
            "Talentoso",
            "Capaz",
            "Apto",
            "Perito",
            "Profissional",
            "Experiente",
            "Sábio",
            "Inteligente",
            "Astuto",
            "Esperto",
            "Sapiência",
            "Discernidor",
            "Intuitivo",
            "Compreensivo",
            "Entendido",
            "Perceptivo",
            "Visionário",
            "Perspicaz",
            "Sagaz",
            "Reflexivo",
            "Juiz",
            "Prudente",
            "Racional",
            "Lógico",
            "Razoável",
            "Criativo",
            "Imaginativo",
            "Inovador",
            "Pensador",
            "Artista",
            "Músico",
            "Escritor",
            "Poeta",
            "Pintor",
            "Escultor",
            "Inventor",
            "Cientista",
            "Pesquisador",
            "Desenvolvedor",
            "Engenheiro",
            "Programador",
        ];

        const prefixosFemininos = [
            "Brava",
            "Forte",
            "Ágil",
            "Corajosa",
            "Destemida",
            "Valente",
            "Heroica",
            "Audaz",
            "Astuta",
            "Feroz",
            "Nobre",
            "Leal",
            "Devota",
            "Sábia",
            "Astuta",
            "Virtuosa",
            "Genial",
            "Talentosa",
            "Inovadora",
            "Inventiva",
            "Criativa",
            "Visionária",
            "Líder",
            "Mestra",
            "Senhora",
            "Protetora",
            "Guardiã",
            "Defensora",
            "Lutadora",
            "Guerreira",
            "Comandante",
            "Capitã",
            "Vencedora",
            "Triunfadora",
            "Conquistadora",
            "Exploradora",
            "Aventureira",
            "Navegadora",
            "Pioneira",
            "Descobridora",
            "Desbravadora",
            "Buscadora",
            "Investigadora",
            "Pesquisadora",
            "Cientista",
            "Inventora",
            "Engenheira",
            "Programadora",
            "Desenvolvedora",
            "Construtora",
            "Arquiteta",
            "Designer",
            "Planejadora",
            "Estratégista",
            "Consultora",
            "Mentora",
            "Treinadora",
            "Instrutora",
            "Educadora",
            "Professora",
            "Tutora",
            "Orientadora",
            "Monitora",
            "Supervisora",
            "Administradora",
            "Gestora",
            "Coordenadora",
            "Diretora",
            "Executiva",
            "Presidente",
            "Empresária",
            "Empreendedora",
            "Investidora",
            "Financista",
            "Bancária",
            "Contadora",
            "Patrocinadora",
            "Promotora",
            "Parceira",
            "Aliada",
            "Camarada",
            "Companheira",
            "Cúmplice",
            "Amiga",
            "Apoiadora",
            "Guardiã",
            "Protetora",
            "Defensora",
            "Heroína",
            "Líder",
        ];

        const sufixosFemininos = [
            "Guerreira",
            "Cavaleira",
            "Pirata",
            "Ninja",
            "Samurai",
            "Viking",
            "Maga",
            "Dragão",
            "Titã",
            "Gigante",
            "Besta",
            "Monstro",
            "Fantasma",
            "Espectro",
            "Assombração",
            "Demônio",
            "Anja",
            "Estranha",
            "Desajustada",
            "Pateta",
            "Maluco",
            "Lunática",
            "Louca",
            "Palhaça",
            "Boba",
            "Mímica",
            "Jogadora",
            "Mágica",
            "Ilusionista",
            "Truqueira",
            "Rascal",
            "Renegada",
            "Rebelde",
            "Fora-da-lei",
            "Bandida",
            "Marauder",
            "Pioneira",
            "Exploradora",
            "Aventureira",
            "Viajante",
            "Peregrina",
            "Nômade",
            "Buscadora",
            "Desbravadora",
            "Guia",
            "Líder",
            "Capitã",
            "Comandante",
            "Chefe",
            "Imperadora",
            "Rainha",
            "Princesa",
            "Duquesa",
            "Baronesa",
            "Condessa",
            "Viscondessa",
            "Escudeira",
            "Senhora",
            "Patriota",
            "Patrona",
            "Heroína",
            "Campeã",
            "Vencedora",
            "Triunfadora",
            "Conquistadora",
            "Defensora",
            "Protetora",
            "Guardiã",
            "Sentinela",
            "Escudo",
            "Combatente",
            "Resistente",
            "Oponente",
            "Competidora",
            "Atleta",
            "Esportista",
            "Concentrada",
            "Determinada",
            "Forte",
            "Energética",
            "Vital",
            "Vigorosa",
            "Potente",
            "Veloz",
            "Ágil",
            "Flexível",
            "Dextra",
            "Habilidosa",
            "Competente",
            "Talentosa",
            "Capaz",
            "Apta",
            "Perita",
            "Profissional",
            "Experiente",
            "Sábia",
            "Inteligente",
            "Astuta",
            "Esperta",
            "Sapiência",
            "Discernidora",
            "Intuitiva",
            "Compreensiva",
            "Entendida",
            "Perceptiva",
            "Visionária",
            "Perspicaz",
            "Sagaz",
            "Reflexiva",
            "Juíza",
            "Prudente",
            "Racional",
            "Lógica",
            "Razoável",
            "Criativa",
            "Imaginativa",
            "Inovadora",
            "Pensadora",
            "Artista",
            "Música",
            "Escritora",
            "Poeta",
            "Pintora",
            "Escultora",
            "Inventora",
            "Cientista",
            "Pesquisadora",
            "Desenvolvedora",
            "Engenheira",
            "Programadora",
        ];

        const generos = ["masculino", "feminino"];
        const genero = generos[Math.floor(Math.random() * generos.length)];

        let prefixoAleatorio, sufixoAleatorio;

        if (genero === "masculino") {
            prefixoAleatorio =
                prefixosMasculinos[
                    Math.floor(Math.random() * prefixosMasculinos.length)
                ];
            sufixoAleatorio =
                sufixosMasculinos[
                    Math.floor(Math.random() * sufixosMasculinos.length)
                ];
        } else {
            prefixoAleatorio =
                prefixosFemininos[
                    Math.floor(Math.random() * prefixosFemininos.length)
                ];
            sufixoAleatorio =
                sufixosFemininos[
                    Math.floor(Math.random() * sufixosFemininos.length)
                ];
        }

        return prefixoAleatorio + " " + sufixoAleatorio;
    };
    const formatToBRL = (value) => {
        value = parseFloat(value);
        return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    };
    return {
        isMobile,
        screenWidth,
        screenHeight,
        formatDate,
        generateRandomTeamName,
        generateRandomSurName,
        formatToBRL,
    };
}
