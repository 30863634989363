import React from "react";
import styled from "styled-components";

import Page from "./page";

const Holder = styled.div`
    background-color: #000;
`;

const Floater = styled.div`
    position: fixed;
    background-color: #000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    z-index: 99;
`;

const Bar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
`;

const Logo = styled.a`
    img {
        height: 40px;
        margin-left: 48px;
    }
`;

const Spacer = styled.div`
    width: 100%;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const Menu = styled.a`
    display: block;
    white-space: nowrap;
    width: fit-content;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    margin: 0px 24px;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const Action = styled.a`
    color: #000;
    font-family: "Space Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00f088;
    padding: 0px 32px;
    height: 80px;

    img {
        margin-right: 16px;
        height: 32px;
    }

    /* @media (max-width: 1024px) {
    display: none;
  } */
`;

export default function Header() {
    return (
        <Holder>
            <Floater>
                <Page>
                    <Bar>
                        <Logo href="/">
                            <img src="/img/logo.svg" alt="Arena Pixel" />
                        </Logo>
                        <Spacer />
                        <Menu href="#about">Sobre</Menu>
                        <Menu href="#games">Jogos</Menu>
                        <Menu href="#space">Espaço</Menu>
                        <Menu href="#events">Eventos</Menu>
                        <Menu href="#contact">Contato</Menu>
                        <Menu href="#faq">FAQ</Menu>
                        <Menu href="/user/login">Minha Conta</Menu>
                        <Action href="https://reservar.arenapixel.com.br">
                            <img src="/img/arrow_header.svg" alt="Reservar" />{" "}
                            Reservar
                        </Action>
                    </Bar>
                </Page>
            </Floater>
        </Holder>
    );
}
