import React from "react";
import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionContainer = styled.div`
    width: 100%;
    padding: 40px 65px;
`;

const Title = styled.h1`
    margin: 0;
    padding-bottom: 25px;
`;

const List = styled.dl`
`;

const Question = styled.span`
    font-weight: 700;
`;

export default function AccordionFaq() {
  return ( 
    <AccordionContainer>
        <Title>FAQ</Title>
        <List>
            <Accordion key="1">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>O que a reserva dá direito? Quanto tempo leva? Qual valor?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    A reserva dá direito ao Circuito Pixel, 5 salas com dinâmicas diferentes sendo elas: Hit, Laser, Quest, Dank e MegaPixel. Você passará por todas elas e o circuito leva em torno de 35 minutos. Os valores do site são por pessoa.
                </AccordionDetails>
            </Accordion>
            <Accordion key="2">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>Quantas pessoas podem jogar por vez?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    É necessário que seu time seja formado por 2 a 6 pessoas. Durante o circuito, você e sua equipe utilizam as salas exclusivamente. Caso estejam em mais pessoas, vocês podem se dividir em quantos times preferirem.
                </AccordionDetails>
            </Accordion>
            <Accordion key="3">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>A Arena possui algum lugar que seja possível  passar o tempo antes ou/e depois do circuito?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    Antes e depois do Circuito, temos um Hub com jogos, atrações, café 3 corações e loja oficial! Você pode ficar o tempo que quiser por lá, é super bacana.                </AccordionDetails>
            </Accordion>
            <Accordion key="4">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>Como funciona o processo de compra? Posso comprar o ingresso presencialmente?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    Você faz a reserva no site, escolhe o dia e horário de preferência, o nome do time e sua bandeira preferida. O pagamento é realizado diretamente no site e logo após é gerado um QR Code para validação na hora do check-in.
                    A compra do ingresso pode ser presencial, porém de acordo com os horários que estarão disponíveis no momento. Se preferir reservar um horário em específico, recomendamos que seja feito pelo site.
                </AccordionDetails>
            </Accordion>
            <Accordion key="5">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>Tem alguma promoção para aniversariantes? Menores tem desconto?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    Nesta data especial, o valor do ingresso do aniversariante sai pela metade do preço, levando acima de 5 pessoas. Vale lembrar que a promoção é válida apenas para a data de aniversário, tendo em mãos o RG. Crianças de até 5 anos possuem um desconto de 50% no Circuito.
                </AccordionDetails>
            </Accordion>
            <Accordion key="6">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>Qual a idade recomendada para o circuito? Qual roupa e calçado utilizar?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                    Nossos jogos são para todos os públicos, recomendamos pessoas a partir de 8 anos para aproveitar 100% a experiência. Além disso, recomendamos roupas e calçados confortáveis. É proibido jogar descalço, com sandálias abertas, saltos e chinelos.
                </AccordionDetails>
            </Accordion>
            <Accordion key="7">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ backgroundColor: "rgb(0, 240, 136)"}}
                >
                    <Question>Qual a política de cancelamento e reagendamento da Arena Pixel?</Question>
                 </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgb(0, 240, 136)"}}>
                Os ingressos são válidos até a data definida na hora da compra, podendo ser alteradas em até 24h antes, sem custo. Exceto em caso de diferença tarifária, podendo o cliente pagar a diferença do valor. <br/><br/><b>NO-SHOW</b>
                <br/><br/>Será considerado No-Show caso o cliente não compareça na data e horário definidos no momento da compra. Ingressos caracterizados como No-Show não poderão ser cancelados, reembolsados ou reagendados. A tolerância máxima de atraso é de 30 minutos.
                <br/><br/><b>CANCELAMENTOS</b>
                <br/><br/>Nos termos do artigo 49 do Código de Defesa do Consumidor, o direito de arrependimento poderá ocorrer em até 7 dias corridos após a compra online, uma vez que a reserva não seja nas próximas 24 horas e o ingresso não tenha sido utilizado. <br />O cancelamento deve ser solicitado via e-mail contato@arenapixel.com.br. <br/> No e-mail deve ser informado o número da compra e o motivo do cancelamento. A solicitação deve ser enviada pelo mesmo e-mail que a compra foi realizada. Os valores somente serão reembolsados ao titular.<br/>
                </AccordionDetails>
            </Accordion>
        </List>
    </AccordionContainer>
  );
};
