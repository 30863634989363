import React from "react";
import styled from "styled-components";

import Page from "../page";

const Holder = styled.div`
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const ImageHolder = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 24px;

    &.left {
        justify-content: start;
    }

    &.right {
        justify-content: end;
    }

    @media (max-width: 1024px) {
        display: block;
    }
`;

const Image = styled.img`
    width: 60%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export default function Gallery() {
    return (
        <Holder>
            <Page>
                <ImageHolder className="left">
                    <Image src="/img/hub1.jpg" />
                </ImageHolder>
                <ImageHolder className="right">
                    <Image src="/img/hub2.jpg" />
                </ImageHolder>
                <ImageHolder className="left">
                    <Image src="/img/hub3.jpg" />
                </ImageHolder>
                <ImageHolder className="right">
                    <Image src="/img/hub4.jpg" />
                </ImageHolder>
                <ImageHolder className="left">
                    <Image src="/img/hub5.jpg" />
                </ImageHolder>
            </Page>
        </Holder>
    );
}
