import React from "react";
import styled from "styled-components";
import Page from "../page";

const Holder = styled.div`
    background: #111;
`;

const Bar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 200px;

    @media (max-width: 1024px) {
        padding-top: 64px;
    }
`;

const GreenBar = styled.div`
    width: 100%;
    height: 120px;
    background: #00f088;
    padding-top: 200px;
    position: relative;
    z-index: 0;

    img {
        position: absolute;
        left: 120px;
        top: 0px;
        margin-top: -140px;
    }

    @media (max-width: 1024px) {
        height: 72px;
        padding-top: 0px;

        img {
            height: 155px;
            left: auto;
            right: -40px;
            top: 50%;
            margin-top: 0px;
            transform: translate(0, -50%);
        }
    }
`;

const Spacer = styled.div`
    width: 233px;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const Blocks = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 1024px) {
        display: block;
    }
`;

const Block1 = styled.div`
    height: 700px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
    width: 50%;

    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
    }
`;

const BlueBlock = styled.div`
    padding: 0px 48px;
    height: 382px;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    background: #344bff;
    width: 100%;

    @media (max-width: 1024px) {
        padding: 16px 16px 104px 16px;
        height: auto;
    }
`;

const BlueBlockTitle = styled.div`
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 50px */

    @media (max-width: 1024px) {
        font-size: 32px;
    }
`;

const BlueBlockLabel = styled.div`
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 30px */
    padding-top: 8px;
    padding-bottom: 54px;
`;

const BlueBlockForm = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    button {
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: #00f088;
        padding: none;
        cursor: pointer;

        img {
            height: 32px;
        }

        @media (max-width: 1024px) {
            height: 66px;
        }
    }

    input {
        width: 100%;
        border: none;
        background: #fff;
        height: 88px;
        color: #6f6f6f;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 30px */
        padding: 16px;

        @media (max-width: 1024px) {
            height: 66px;
        }
    }
`;

const Block2 = styled.div`
    height: 700px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: end;
    width: 50%;

    @media (max-width: 1024px) {
        height: auto;
        width: 100%;
    }
`;

const WhiteBlock = styled.div`
    height: 319px;
    width: 100%;
    padding: 32px 48px;
    position: relative;
    background: #fff;

    span {
        display: block;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 40px */

        a {
            color: #000;
            text-decoration: underline;
        }

        @media (max-width: 1024px) {
            font-size: 24px;
        }
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
        padding-bottom: 104px;
    }
`;

const ContentLink = styled.a`
    width: fit-content;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px; /* 233.333% */
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: start;
    background: #00f088;
    padding: 0px 24px;
    position: absolute;
    right: 0px;
    bottom: 0px;

    img {
        margin-left: 16px;
    }

    @media (max-width: 1024px) {
        font-size: 16px;
        justify-content: center;

        img {
            height: 20px;
        }
    }
`;

const Block3 = styled.div`
    height: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    width: 233px;
    background: #f7ec28;
    padding: 48px 0px;
    position: relative;

    @media (max-width: 1024px) {
        width: 100%;
        justify-content: start;
        align-items: start;
        height: auto;
        padding: 16px 16px 104px 16px;
    }
`;

const SocialLinks = styled.a`
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--Preto, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    text-decoration-line: underline;
    margin-top: 16px;
`;

const SocialImage = styled.img`
    position: absolute;
    left: -139px;
    top: 0px;

    @media (max-width: 1024px) {
        height: 155px;
        top: auto;
        bottom: 16px;
        right: -40px;
        left: auto;
    }
`;

const Footer = styled.div`
    width: 100%;
    height: 160px;
    background-image: url(/img/footer.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 1024px) {
        /* height: 80px; */
    }
`;

export default function Contact({ id }) {
    return (
        <Holder>
            <Page overflow>
                <Bar>
                    <GreenBar>
                        <img src="/img/smile.svg" alt="Arena Pixel" />
                    </GreenBar>
                    <Spacer />
                </Bar>
                <Blocks>
                    <Block1>
                        <BlueBlock>
                            <BlueBlockTitle>
                                Acompanhe as novidades
                            </BlueBlockTitle>
                            <BlueBlockLabel>
                                Inscreva-se e receba a nossa newsletter
                            </BlueBlockLabel>
                            <BlueBlockForm>
                                <form
                                    action="https://arenapixel.us8.list-manage.com/subscribe/post?u=ed338784bd4d87b3d347df0db&amp;id=9bb225d525&amp;f_id=00207ce1f0"
                                    method="post"
                                    target="_blank"
                                >
                                    <input
                                        type="email"
                                        name="EMAIL"
                                        id="mce-EMAIL"
                                        placeholder="Seu e-mail"
                                        required
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "-5000px",
                                        }}
                                        aria-hidden="true"
                                    >
                                        <input
                                            type="text"
                                            name="b_ed338784bd4d87b3d347df0db_9bb225d525"
                                            tabindex="-1"
                                            value=""
                                        />
                                    </div>
                                    <button type="submit">
                                        <img
                                            src="/img/arrow_header.svg"
                                            alt="Email"
                                        />
                                    </button>
                                </form>
                            </BlueBlockForm>
                        </BlueBlock>
                    </Block1>
                    <Block2>
                        <WhiteBlock>
                            <img src="/img/mini_smile.svg" alt="Endereço" />
                            <span>
                                <br />
                                Rua Girassol 925, Vila Madalena
                                <br />
                                Quarta e domingo - 10h às 20h
                                <br />
                                Quinta, sexta e sabado - 10h às 22h
                                <br />
                                <a href="mailto:contato@arenapixel.com.br">
                                    contato@arenapixel.com.br
                                </a>{" "}
                                <br />
                                <a
                                    rel="noreferrer"
                                    href="https://wa.me/551138194001"
                                    target="_blank"
                                >
                                    (11)3819-4001
                                </a>
                            </span>
                            <ContentLink
                                href="https://www.google.com/maps/place/Arena+Pixel/@-23.5513767,-46.6947941,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce57d744e8241d:0xff20401f7b476eb1!8m2!3d-23.5513816!4d-46.6922192!16s%2Fg%2F11vq68gmxv?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
                                target="_blank"
                            >
                                Ver no mapa
                                <img src="/img/arrow_header.svg" alt="Mapa" />
                            </ContentLink>
                        </WhiteBlock>
                    </Block2>
                    <Block3>
                        <SocialImage
                            className="social"
                            src="/img/social_image.svg"
                            alt="Contato"
                        />
                        <SocialLinks
                            href="https://instagram.com/arenapixel"
                            target="_blank"
                        >
                            Instagram{" "}
                            <img
                                src="/img/arrow_social.svg"
                                alt="Social Media"
                            />
                        </SocialLinks>
                        <SocialLinks
                            href="https://tiktok.com/@arenapixel"
                            target="_blank"
                        >
                            TikTok{" "}
                            <img
                                src="/img/arrow_social.svg"
                                alt="Social Media"
                            />
                        </SocialLinks>
                        <SocialLinks
                            href="https://facebook.com/arenapixelbr"
                            target="_blank"
                        >
                            Facebook{" "}
                            <img
                                src="/img/arrow_social.svg"
                                alt="Social Media"
                            />
                        </SocialLinks>
                    </Block3>
                </Blocks>
            </Page>
            <Footer id={id} />
        </Holder>
    );
}
