import React from "react";

export default function Target({ color, hand, callback, height }) {
  return (
    <svg 
      style={{ cursor: hand ? "pointer" : "default" }}
      width={height ? height : 130} 
      height={height ? height : 130} 
      viewBox="0 0 200 200" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      onClick={
            hand
              ? () => {
                  callback(color);
                }
              : null
      }
    >
      <g clip-path="url(#clip0_167_1668)">
        <path d="M177.82 57.1429C174.29 57.1429 171.42 54.5827 171.42 51.4326V37.1419C171.42 32.4116 167.58 28.5714 162.85 28.5714H149.25C145.72 28.5714 142.85 26.0113 142.85 22.8611V0H57.14V22.8611C57.14 26.0213 54.58 28.5714 51.43 28.5714H37.14C32.41 28.5714 28.57 32.4116 28.57 37.1419V50.7425C28.57 54.2727 26.01 57.1429 22.86 57.1429H0V142.857H22.18C25.71 142.857 28.58 145.417 28.58 148.567V162.858C28.58 167.588 32.42 171.429 37.15 171.429H50.75C54.28 171.429 57.15 173.989 57.15 177.139V200H142.86V177.829C142.86 174.299 145.42 171.429 148.57 171.429H162.86C167.59 171.429 171.43 167.588 171.43 162.858V149.257C171.43 145.727 173.99 142.857 177.14 142.857H200V57.1429H177.82ZM171.42 136.467C171.42 139.997 168.86 142.867 165.71 142.867H151.42C146.69 142.867 142.85 146.707 142.85 151.438V165.038C142.85 168.568 140.29 171.439 137.14 171.439H63.54C60.01 171.439 57.14 168.878 57.14 165.728V151.438C57.14 146.707 53.3 142.867 48.57 142.867H34.97C31.44 142.867 28.57 140.307 28.57 137.157V63.5432C28.57 60.013 31.13 57.1429 34.28 57.1429H48.57C53.3 57.1429 57.14 53.3027 57.14 48.5724V34.2817C57.14 31.1216 59.7 28.5714 62.85 28.5714H136.45C139.98 28.5714 142.85 31.1316 142.85 34.2817V48.5724C142.85 53.3027 146.69 57.1429 151.42 57.1429H165.02C168.55 57.1429 171.42 59.703 171.42 62.8531V136.457V136.467Z"
           fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_167_1668">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
