import React from "react";
import styled from "styled-components";
import Page from "../page";
import Accordion from "./accordionFaq";

const Main = styled.div`
    background: #111;
`;

const Holder = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;

    @media (max-width: 1024px) {
        display: block;
        padding: 16px;
    }
`;

const Block = styled.div`
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const BlueBlock = styled.div`
    width: 120px;
    height: 120px;
    background: #344bff;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    height: auto;

    @media (max-width: 1024px) {
        height: auto;
    }
`;

const ContentBlock = styled.div`
    width: 100%;
    height: auto;
    background: #344bff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    margin-top: 120px;
    @media (max-width: 1024px) {
        height: auto;
    }
`;

export default function Faq({ id }) {
    return (
        <Main id={id}>
            <Page>
                <Holder>
                    <Block>
                        <BlueBlock />
                    </Block>
                    <Content>
                        <ContentBlock>
                            <Accordion />
                        </ContentBlock>  
                    </Content>
                </Holder>
            </Page>
        </Main>
    );
}
